.news_page_sections {
    padding: 20px;
}

.new_image_box {
    width: 100%;
    min-height: 500px;
    max-height: 700px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.new_image_box img {
    width: 800px;
    height: 100%;
    object-fit: cover;
}

.context_data {
    width: 100%;
    padding: 20px 80px;
}

.context_data h1 {
    text-align: center;
    font-weight: bold;
    margin: 20px 0;
    font-size: 2rem;
}

.context_data p {
    font-size: 1.2rem;
    text-align: justify;
}

.image_item_box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 30vw);
    grid-gap: 15px;
}

.image_item_data {
    width: 100%;
    height: 100%;
    object-fit: cover;
}