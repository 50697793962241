.header_sections {
  width: 100%;
  z-index: 1000;
}

.top_header_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.work_info_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.work_time_image {
  margin-right: 10px;
  size: inherit;
}

.work_info {
  font-size: 16px;
}

.social_media_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_media {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-left: 5px;
}

.navbat_styles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.logo_link {
  text-decoration: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_link h1 {
  font-weight: bold;
}

.logo_header {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.dropdown {
  float: left;
  overflow: hidden;
  z-index: 100;
}

.dropdown .dropbtn {
  font-size: 14px;
  border: none;
  outline: none;
  padding: 5px 10px;
  margin: 0;
  font-weight: bold;
  align-items: center;
  border-radius: 5px;
  display: flex;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: #222 !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.navbar_element_box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav_element {
  font-size: 14px;
  padding: 8px 10px;
  font-weight: bold;
  border-radius: 5px;
  margin-left: 5px;
  text-decoration: none;
}

.mode_image {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  cursor: pointer;
}

.switch-container {
  margin-left: 15px;
}

.switch-container input:checked + label {
  color: blue;
}

.switch-container label {
  display: flex;
  width: 75px;
  height: 35px;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  background: #222;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
}

.switch-container label i {
  font-size: 18px;
}
.switch-container label .fa-sun {
  color: gold;
  transition: 0.8s;
}
.switch-container label .fa-moon {
  color: #fff;
  transition: 0.8s;
}
.switch-container label .ball {
  display: none;
}

.switch-container input:checked + label .fa-sun {
  transform: translateX(-43px);
}
.switch-container input:checked + label .fa-moon {
  transform: translateX(43px);
}

.switch-container label .fa-sun {
  opacity: 1;
}
.switch-container input:checked + label .fa-sun {
  opacity: 0;
}
.switch-container label .fa-moon {
  opacity: 0;
}
.switch-container input:checked + label .fa-moon {
  opacity: 1;
}

.switch-container label .ball {
  position: absolute;
  display: block;
  width: 25px;
  height: 25px;
  top: 5px;
  left: 5px;
  background: #fff;
  border-radius: 50%;
  transition: 0.8s;
}

.switch-container input:checked + label .ball {
  transform: translateX(40px);
}

.switch-container label .fa-moon {
  transform: translateX(43px);
}
.switch-container input:checked + label .fa-moon {
  transform: translateX(0);
}

.switch-container label {
  transition: 0.3s;
}
.switch-container input:checked + label {
  background: #c0c0c0;
}
.switch-container input:checked + label .ball {
  background: #222;
}
.switch-container input {
  display: none;
}
.switch-container input:checked + label .fa-sun {
  transform: translateX(-43px) rotate(160deg);
}
.switch-container label .fa-moon {
  transform: translateX(43px) rotate(250deg);
}
.switch-container input:checked + label .fa-moon {
  transform: translateX(0), rotate(0);
}
