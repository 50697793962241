/** @format */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
}

p {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
}

.open_modal_element {
  display: none !important;
}

.logo_box img {
  width: 50px !important;
  height: 50px !important;
  margin: 10px 0;
}

.footer-distributed h3 {
  font-weight: bold;
  margin-left: 10px;
}

.certificate_box {
  width: 400px;
  padding: 20px;
  margin-top: 20px;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.certificate_box img {
  width: 300px;
  height: 430px;
}

.certificate_box h2 {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .social_media_box {
    position: absolute;
    z-index: 10;
    top: 20px;
    left: -200%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar_element_box {
    top: 0;
    left: -200%;
    position: absolute;
    height: 100%;
    width: 100%;
    max-height: 50vh;
    display: grid;
    justify-content: center;
    align-content: center;
    background-color: slategray;
    z-index: 3;
  }

  .close_icon_close {
    position: absolute;
    z-index: 3;
    top: 20px;
    left: -200%;
    width: 40px;
    height: 40px;
  }

  .open_modal_element {
    display: inline-flex !important;
  }

  .navbar_element_box .dropdown {
    margin-bottom: 10px;
    z-index: 1;
  }

  .nav_element {
    margin-bottom: 10px;
  }

  .close_icon_open {
    position: absolute !important;
    top: 80px !important;
    right: 20px !important;
  }

  .open_navbar {
    top: 0 !important;
    left: 0 !important;
  }

  .dropdown-content a {
    z-index: 3;
  }

  .dropdown-content {
    z-index: 3;
  }

  .open_social_media {
    top: 20px !important;
    left: 20px !important;
  }

  .dropbtn {
    z-index: 1;
  }

  .logo_box img {
    width: 50px;
    height: 50px;
  }

  .intro_sections {
    height: 450px;
    width: 100%;
  }
  .intro_info_box h1 {
    font-size: 40px;
  }
  .intro_info_box p {
    font-size: 16px;
  }
  .orb {
    width: 180px;
    margin-top: 40px;
    height: 150px;
    margin-left: 5px;
  }
  .intro_icon {
    font-size: 20px;
  }
  .orb_value {
    font-size: 18px;
  }
  .orb_label {
    font-size: 14px;
  }
  .services_section,
  .news_section,
  .maps_section {
    padding: 20px;
  }
  .service_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }
  .services_card {
    width: 100%;
  }
  .news_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }

  .news_container a {
    width: 95%;
  }

  .input_box {
    flex-wrap: wrap;
    gap: 10px;
  }
  .input-group input,
  .input-group textarea,
  .submit_button {
    width: 300px;
  }
  .content_box {
    width: 100%;
  }

  .maps_container {
    margin-top: 20px;
    width: 400px;
    height: 300px;
  }
  .footer-distributed {
    padding: 20px;
    text-align: left;
  }
  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    text-align: left;
  }
  .new_image_box img {
    width: 100%;
  }
  .context_data {
    padding: 20px;
  }
  .context_data h1 {
    font-size: 1.2rem;
    margin: 15px 0;
  }
  .context_data p {
    font-size: 1rem;
  }
  .information_use_poroduct {
    margin-left: 30px;
    margin-top: 20px;
  }

  .about_section_page {
    padding: 20px;
  }
  .about_section_page h1 {
    font-size: 2rem;
  }
  .about_context_box p {
    font-size: 1.2rem;
    width: 100%;
  }
  .about_context_box img {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
}

@import url(./styles/header.css);
