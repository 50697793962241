/** @format */

.about_section_page {
  width: 100%;
  padding: 20px 0px;
}

.about_context_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

.about_section_page h1 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

.about_context_box p {
  font-size: x-large;
  width: 800px;
  text-align: justify;
  margin-right: 20px;
}
.about_context_box span {
  font-weight: 800;
  font-size: x-large;
}

.about_context_box  ul li{
  font-size: large;
  font-weight: 600;
}

.about_context_box img {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
    margin-left: 30px;
}

.about_context_box  ul {
    margin-top: 5px;
    margin-left: 50px;
}

.team_section_container {
    margin-top: 30px;
}

.team_information_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.team_box {
    padding: 5px;
    border-radius: 10px;
    width: 300px;
    height: 250px;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    text-align: center;
}

.team_box img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 10px;
    object-position: top;
}

.team_box span{
    font-weight: bold;

}