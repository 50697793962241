/** @format */

.use_product {
  display: flex;
  justify-content: space-between;
}


.information_use_poroduct h3 {
    margin-bottom: 10px;
}

.use_pdoroduct_images {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;
}

.use_pdoroduct_images .use_product_img_box {
    width: 250px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
}

.use_product_img_box img {
    width: 250px;
    height: 150px;
    object-fit: cover;
    object-position: top;
}

.information_use_poroduct {
    margin-left: 80px;
}

.product_images_item {
    width: 100%;
    height: 400px;
    object-fit: cover;
}