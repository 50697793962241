/** @format */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

.intro_sections {
  width: 100%;
  height: 35vw;
  background-size: cover;
  background-repeat: no-repeat;
  animation: intro_sections 20s ease-in-out infinite;
  position: relative;
}

.intro_sections::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

@keyframes intro_sections {
  0% {
    background-image: url("../../images/introBg1.jpg");
  }
  33% {
    background-image: url("../../images/introBg2.jpg");
  }
  66% {
    background-image: url("../../images/introBg3.jpg");
  }
  100% {
    background-image: url("../../images/introBg4.jpg");
  }
}

.intro_sections > * {
  position: relative;
  z-index: 2;
}

.intro_circle_info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.orb {
  width: 300px;
  margin-top: 80px;
  background-color: rgba(256, 256, 256, 0.3);
  height: 200px;
  margin-left: 10px;
  align-content: center;
  text-transform: uppercase;
  border-radius: 20px;
}

.orb_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.intro_icon {
  margin-right: 15px;
  font-size: xx-large;
}

.orb_value {
  margin-right: 10px;
  font-weight: 700;
  font-size: xx-large;
}

.intro_info_box {
  width: 100%;
  height: 200px;
  align-content: center;
  text-align: center;
  padding: 10px;
}

.intro_info_box h1 {
  font-size: 60px;
  font-weight: 800;
}

.intro_info_box p {
  font-size: x-large;
}

.services_section,
.news_section,
.maps_section {
  padding: 20px 50px;
}

.services_section h2,
.news_section h2,
.maps_section h2 {
  font-size: xx-large;
  font-weight: 800;
  margin-bottom: 10px;
}

.services_section p,
.news_section p,
.maps_section p {
  margin-bottom: 10px;
}

.service_box {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}

.service_box img {
  width: 80px;
  height: 80px;
}

.services_card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  background: white;
  border-radius: 10px;
  padding: 10px;
}

.services_card p {
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.news_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  /* flex-wrap: wrap; */
  overflow-x: scroll;
  /* white-space: nowrap; ichidagi elementlarni qator bo‘ylab joylashtiradi */
  overflow-x: auto; /* scroll o‘rniga auto ishlatish yaxshiroq */
  gap: 10px;
}

.news_container {
  width: 280px;
  height: 550px;
  border-radius: 10px;
  margin-left: 10px;
  padding: 10px;
  position: relative;
}

.news_container img {
  width: 260px;
  height: 245px;
  border-radius: 10px;
  object-fit: cover;
}

.news_container .news_date {
  font-size: small;
  text-align: center;
}

.news_container h3 {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.news_container p {
  font-size: 12px;
  font-weight: bold;
}

.news_container a {
  width: 250px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  left: 8px;
}

.maps_container {
  width: 600px;
  height: 400px;
  border: none;
  border-radius: 10px;
}

.input_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.content_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content_box h4 {
  font-size: 30px;
  font-weight: bold;
}

.input-group {
  position: relative;
  margin: 10px 0;
}

.input-group label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #fff;
  padding: 0 5px;
  pointer-events: none;
  transition: 0.5s;
}

.input-group input,
.input-group textarea {
  width: 420px;
  font-size: 16px;
  padding: 0 10px;
  outline: none;
  border-radius: 5px;
  border: none;
}

.input-group input {
  height: 50px;
}

.input-group textarea {
  height: 200px;
}

.input-group input:focus ~ label,
.input-group input:valid ~ label,
.input-group textarea:focus ~ label,
.input-group textarea:valid ~ label {
  top: 0;
  font-size: 12px;
}

.submit_button {
  width: 420px;
  height: 50px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: medium;
}

.logo_container_parents {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.logo_container_parents img {
  width: 300px;
  height: 50px;
  object-fit: fill;
  margin-left: 20px;
  padding: 0 10px;
  background-color: rgba(256, 256, 256, 0.5);
}

.project_container {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex-wrap: wrap; */
  overflow-x: scroll;
  gap: 10px;
}

.project_data_box {
  width: 340px;
  height: 370px;
  padding: 10px;
  border-radius: 10px;
}

.project_data_box h3 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.project_data_box p {
  text-align: justify;
  font-weight: 700;
}

.project_images_head {
  width: 100%;
  height: 200px;
}
